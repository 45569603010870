import { navigate } from 'gatsby';
import React from 'react';
import Button from "../components/Button";
import Title from "../components/Hero/components/Title";

const NotFoundPage = () => {
  const title = "<span>Ops!</span> Página não encontrada.";

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center">
        <Title>404</Title>

        <Title>{title}</Title>

        <p className="mb-8 text-center text-gray-500 md:text-lg">
          Não conseguimos encontrar a página que você está procurando.
        </p>
        <Button
          preset="solidCyan"
          onClick={
            () => {
              navigate("/")
            }
          }
          type="button"
        >
          Voltar ao início
        </Button>
      </div>
    </div >
  )
}
export default NotFoundPage
